import { v4 as uuidv4 } from 'uuid';
import { Axios } from 'axios';
import Resources from 'components/data/Resources/components';
import DataHelpers from 'components/data/DataHelpers';
import Templates from 'components/data/Templates';
import { convertTemplateSubTypeToCreativeSubType } from 'components/creative-management-v2/utilities';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/**
 * Class CreativeBuilderHelpers
 * This class is a helper class with several features that are used in the CreativeBuilder at several spots.
 */
class CreativeBuilderHelpers {
    /**
     * Apply data variables to a template
     * This replaces the template designer fields with the corresponding variables
     * @param {*} itemData The individual frame data in the template
     * @param {*} dataVariablesInTemplate The variables as set in the template setup
     * @param {*} data The variables as need to be inserted
     * @param {{revertVisibility: boolean}} options - Does visibility need to be inverted
     */
    static applyDataVariablesToTemplate(assetData, dataVariablesInTemplate, variables, options = {}) {
        // Loop through all frames
        for (const frameType in dataVariablesInTemplate) {
            // Loop through layers
            for (const layer in dataVariablesInTemplate[frameType]) {
                // Loop through properties
                const propertiesToReplace = dataVariablesInTemplate[frameType][layer];
                for (const property in propertiesToReplace) {
                    const variableToReplace = propertiesToReplace[property];
                    if (variables[variableToReplace] !== undefined) {
                        const targetLocation = layer + '.' + property;
                        let value = variables[variableToReplace];
                        // Text property, handle subobject
                        if (property === 'text') {
                            value = Number.isInteger(variables[variableToReplace]) ? variables[variableToReplace] + '' : variables[variableToReplace];
                        }

                        // Image, handle data
                        if (property === 'image' || property === 'background-image') {
                            const url = variables[variableToReplace].trim();

                            if (variables[variableToReplace]) {
                                value = {
                                    url: url,
                                    fileType: 'image',
                                    extension: 'jpg',
                                    assetGalleryInput: true,
                                    originalImage: variables[variableToReplace]
                                };
                            }
                            // Background image variable
                            if (variableToReplace === 'backgroundImage') {
                                value.isBackgroundImage = true;
                                value.backgroundModel = targetLocation;
                            }
                        }

                        // Convert true and false string values in the feed
                        if (property === 'visibility' && value && value === 'FALSE') {
                            value = false;
                        }
                        if (property === 'visibility' && value && value === 'TRUE') {
                            value = true;
                        }

                        // Template designer visibility is inverted and from the feed it is not, so the value also needs to be inverted.
                        if (options.revertVisibility && property === 'visibility') {
                            value = !value;
                        }

                        // Set variable
                        DataHelpers.setModel(assetData, targetLocation, value);
                    }
                }
            }
        }
        return assetData;
    }

    /**
     * Get data variables from template
     * This takes the values from the template designer data
     * @param {*} assetData
     * @param {*} dataVariablesInTemplate
     * @returns
     */
    static getDataVariablesFromTemplate(assetData, dataVariablesInTemplate) {
        const variables = {};

        // Loop through all frames
        for (const frameType in dataVariablesInTemplate) {
            // Loop through layers
            for (const layer in dataVariablesInTemplate[frameType]) {
                // Loop through properties
                const propertiesToReplace = dataVariablesInTemplate[frameType][layer];
                for (const property in propertiesToReplace) {
                    const variableToReplace = propertiesToReplace[property];
                    const targetLocation = layer + '.' + property;
                    let value = DataHelpers.getValue(assetData, targetLocation);

                    // Text property, handle subobject
                    if (value) {
                        if (property === 'text') {
                            value = value.value;
                        }
                        // Image, handle data
                        if (property === 'image' || property === 'background-image') {
                            if (value) {
                                value = value.url;
                            }
                        }

                        variables[variableToReplace] = value;
                    }
                }
            }
        }
        return variables;
    }

    /**
     * Add item to a builder data object
     * This takes the template as the input and sets up the new data object used by the builder
     * @param {*} dataList The list with all the templates in the current cmapaign
     * @param {*} newItemTemplate The item template full JSON as in the DB
     */
    static addNewItemFromTemplate(dataList, newItemTemplate) {
        if (!dataList) {
            dataList = [];
        }

        // Add item to list
        const uuid = 'i_' + uuidv4().replace(/-/g, '');
        const newItem = {
            uuid: uuid,
            identifier: newItemTemplate.identifier,
            type: convertTemplateSubTypeToCreativeSubType(newItemTemplate.type, newItemTemplate.identifier)
        };
        const dataListNew = [...dataList, newItem];

        // Create default setup
        const itemData = {
            title: newItemTemplate.title,
            frames: 1, // The number of frames
            type: convertTemplateSubTypeToCreativeSubType(newItemTemplate.type, newItemTemplate.identifier), // The type of the template
            groupKey: newItemTemplate.groupKey, // The group key of the template
            subsets: ['main'], // An array of the substs
            channelSetup: {
                // Object with the setup of the channel
                settings: {}
            },
            assetSetup: {
                type: convertTemplateSubTypeToCreativeSubType(newItemTemplate.type, newItemTemplate.identifier),
                templateIdentifier: newItemTemplate.identifier,
                settings: {
                    formats: []
                }
            }, // Object with a setup of the media
            data: {
                main: {
                    // The set identifier. Main is always added.
                    channelData: {
                        type: 'none'
                    }, // Channel data for this set
                    assetData: {
                        type: 'none'
                    } // Media data for this set
                }
            }
        };

        // We are using a channel template, so copy defaultdata and the model
        if (newItemTemplate.type === 'socialChannelItem') {
            itemData.channelSetup = {
                templateIdentifier: newItemTemplate.identifier,
                type: newItemTemplate.type,
                channel: newItemTemplate.channel,
                adType: newItemTemplate.adType
            };
            if (newItemTemplate.defaultData) {
                itemData.data.main.channelData = DataHelpers.clone(newItemTemplate.defaultData);
            }
            itemData.channelSetup.settings = newItemTemplate.settings;

            // Set standard static asset
            if (!itemData.channelSetup?.settings?.assetTypes?.includes('image')) {
                itemData.assetSetup = {
                    ...itemData.assetSetup,
                    templateIdentifier: 'video',
                    type: 'staticAsset'
                };
            } else {
                itemData.assetSetup = {
                    ...itemData.assetSetup,
                    templateIdentifier: 'image',
                    type: 'staticAsset'
                };
            }

            // Add standard asset for carousel
            if (itemData.channelSetup?.adType === 'carouselAd' || newItemTemplate.settings.multiFrame) {
                itemData.assetSetup = {
                    ...itemData.assetSetup,
                    frames: {
                        frame1: {
                            templateIdentifier: 'image',
                            type: 'staticAsset'
                        }
                    }
                };
            }
        }
        // We have a media template, e.g. an image, display ad or video
        else {
            let i;

            itemData.assetSetup = {
                templateIdentifier: newItemTemplate.identifier,
                type: newItemTemplate.type,
                previewUrl: newItemTemplate.previewUrl,
                settings: newItemTemplate.settings ? newItemTemplate.settings : {}
            };

            // Empty object results in an array
            if (Array.isArray(itemData.assetSetup.settings)) {
                itemData.assetSetup.settings = {};
            }

            // Copy all the available formats for the selector of formats in the interface.
            if (newItemTemplate.settings && newItemTemplate.settings.formats) {
                const originalFormats = DataHelpers.clone(newItemTemplate.settings.formats);
                const formats = CreativeBuilderHelpers.createAssetFormatsArray(newItemTemplate.type, originalFormats);

                // The formats field contains all checked formats
                itemData.assetSetup.settings.formats = formats;

                // The available formats are all formats that are available in the template
                itemData.assetSetup.settings.availableFormats = DataHelpers.clone(formats, false);

                // Format sets
                if (newItemTemplate.settings.formatSets) {
                    const originalFormatSets = DataHelpers.clone(newItemTemplate.settings.formatSets);
                    for (i in originalFormatSets) {
                        originalFormatSets[i].formats = CreativeBuilderHelpers.createAssetFormatsArray(newItemTemplate.type, originalFormatSets[i].formats);
                    }
                    itemData.assetSetup.settings.formatSets = originalFormatSets;
                }
            }

            // In case we have a dynamically designed template, we fetch it from the template setup
            const designerTemplateFormats = DataHelpers.getValue(newItemTemplate, 'templateSetup.formats');
            if (designerTemplateFormats && designerTemplateFormats.length > 0) {
                for (i in designerTemplateFormats) {
                    designerTemplateFormats[i].format = designerTemplateFormats[i].key;
                }
                itemData.assetSetup.settings.formats = DataHelpers.clone(designerTemplateFormats, false);
                itemData.assetSetup.settings.availableFormats = DataHelpers.clone(designerTemplateFormats, false);
            }

            // Default data is set, add to the newly created item
            if (newItemTemplate.defaultData) {
                itemData.data.main.assetData = DataHelpers.clone(newItemTemplate.defaultData);

                // In case we have frames in the defaultData, add them
                if (newItemTemplate.defaultData && newItemTemplate.defaultData.frames) {
                    itemData.frames = Object.keys(newItemTemplate.defaultData.frames).length;
                }
            }
        }

        // ReturnData
        return {
            uuid,
            dataList: dataListNew,
            itemData: itemData
        };
    }

    /**
     * createAssetFormatsArray
     * Uses the formats from the template as an input, and enriches the data to create the final output
     */
    static createAssetFormatsArray(type, formats) {
        const templateFormats = Resources.get('templateFormats');

        const typeTranslateTable = {
            displayAd: 'display',
            dynamicVideo: 'video',
            dynamicAfterEffects: 'video',
            dynamicInDesign: 'print',
            dynamicImage: 'image',
            dynamicPDF: 'print'
        };

        // Formats are set up as an array
        const outputFormats = [];
        if (Array.isArray(formats)) {
            formats.forEach((formatKey) => {
                // Array of formats
                if (typeof formatKey === 'string' || formatKey instanceof String) {
                    let formatData = { format: formatKey };

                    // Check for exact size
                    const formatKeyParts = formatKey.split('_');
                    if (formatKeyParts[0] === 'format') {
                        try {
                            const sizes = formatKeyParts[1].split('x');
                            formatData.width = parseInt(sizes[0]);
                            formatData.height = parseInt(sizes[1]);
                            formatData.title = 'Format ' + sizes[0] + 'x' + sizes[1];
                        } catch (e) {
                            console.log('createAssetFormatsArray', e);
                        }
                    } else {
                        // Check for predefined format
                        try {
                            if (templateFormats[typeTranslateTable[type]][formatKey]) {
                                formatData = { ...templateFormats[typeTranslateTable[type]][formatKey], ...formatData };
                            }
                        } catch (e) {
                            console.log('createAssetFormatsArray', e);
                        }
                    }
                    if (formatData.key) {
                        formatData.format = formatData.key;
                    }

                    outputFormats.push(formatData);
                }
                // Already correct, we have an object
                else {
                    if (formatKey.key) {
                        formatKey.format = formatKey.key;
                    }

                    outputFormats.push(formatKey);
                }
            });
        }
        // Formats are set up as an object
        else {
            Object.keys(formats).forEach((formatKey) => {
                let formatData = { format: formatKey, ...formats[formatKey] };

                try {
                    if (templateFormats[typeTranslateTable[type]][formatKey]) {
                        formatData = { ...templateFormats[typeTranslateTable[type]][formatKey], ...formatData };
                    }
                } catch (e) {
                    console.log('createAssetFormatsArray', e);
                }
                if (formatKey.key) {
                    formatKey.format = formatKey.key;
                }
                outputFormats.push(formatData);
            });
        }

        // Return the new format array with all data
        return outputFormats;
    }

    /**
     * filterFormats
     * Only returns a set of formats
     */
    static filterFormatsArray(formats, filter = []) {
        // No array, return the original
        if (!formats || !formats.filter) {
            return [];
        }

        return formats.filter((formatData) => {
            return filter.includes(formatData.format);
        });
    }

    /**
     * Get format data
     * Get item from an individual format
     */
    static getFormatData(formats, format) {
        let result = {};

        if (!formats || !formats.forEach) {
            return {};
        }

        formats.forEach((formatData) => {
            if (formatData.format === format) {
                result = formatData;
            }
        });

        return result;
    }

    /**
     * Get the type icon of a creative block
     */
    static getTypeIcon(data) {
        let icon = '';
        if (data.channelSetup && data.channelSetup.channel) {
            icon = data.channelSetup.channel;
        } else if (data.type === 'dynamicVideo' || data.type === 'staticAssetVideo') {
            icon = 'video';
        } else {
            icon = 'display';
        }
        return icon;
    }

    /**
     * Determine whether the display for social adds is required.
     * @param {array} value
     * @returns {boolean}
     */
    static getIsSocial = (value) => {
        return value && value.length > 0 && (value[0].type === 'social' || value[0].type === 'socialChannelItem');
    };

    /**
     * Load templates data from server
     * @param {*} data
     */
    static loadTemplates = async (data) => {
        const itemsToLoad = [];

        if (data?.channelSetup?.templateIdentifier) {
            itemsToLoad.push({ type: data.channelSetup.type, identifier: data.channelSetup.templateIdentifier, fullData: true });
        }

        if (data?.assetSetup?.templateIdentifier) {
            itemsToLoad.push({ type: data.assetSetup.type, identifier: data.assetSetup.templateIdentifier, fullData: true });
        }

        // Multi frame social
        if (data?.assetSetup?.frames) {
            Object.keys(data.assetSetup.frames).forEach((frameKey) => {
                const frame = data.assetSetup.frames[frameKey];
                if (frame.templateIdentifier) {
                    itemsToLoad.push({ type: frame.type, identifier: frame.templateIdentifier, fullData: true });
                }
            });
        }

        await Templates.asyncGetTemplatesList(itemsToLoad);
    };
}

export default CreativeBuilderHelpers;
