import React from 'react';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import PreviewFrame from '../../PreviewFrame';
import GenericHelpers from './generic.js';

/**
 * Creates the dynamic image items
 */
export default function (props, onChangeScale, onSelectFormat, onEditBackground) {
    const {
        data,
        subsetActive,
        dataModel,
        language,
        editor,
        isPreview = true,
        showFormatTitle = false,
        selectedFormats = [],
        frameNr,
        loadInView = false,
        specificFormat,
        viewType,
        commentsCountsByCategory = {},
        maxWidthScale,
        maxHeightScale,
        defaultFullSize,
        commentsCategoryKey,
        onCommentsOpen
    } = props;
    let { canEditBackground = false } = props;

    // Get formats
    const formatsData = GenericHelpers.getFormats(data, specificFormat);

    let isSelectable = true;

    if (data.assetSetup && data.assetSetup.settings && data.assetSetup.settings.availableFormats && data.assetSetup.settings.availableFormats.length < 2) {
        isSelectable = false;
    }

    const assetUpdateModel = dataModel + '.data.' + subsetActive + '.assetData';
    const assetData = EditorData.getValueFromModel(assetUpdateModel);
    const assetSetup = EditorData.getValueFromModel('assetSetup', data);
    const positionByLanguage = DataHelpers.getValue(data, 'assetSetup.settings.positionByLanguage') ? true : false;
    const uploadByLanguage = DataHelpers.getValue(data, 'assetSetup.settings.uploadByLanguage') ? true : false;
    let template = {};

    // Designed display ad
    let url = assetSetup.url ? assetSetup.url : assetSetup.previewUrl;
    if (['dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicPDFDesigned'].includes(data.type)) {
        template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');
        if (!template || Object.keys(template).length === 0) {
            return [];
        }
    } else if (['dynamicInDesign'].includes(data.type)) {
        template = Templates.get(data.type, data.assetSetup.templateIdentifier);
        if (!template || Object.keys(template).length === 0) {
            return [];
        }
    }

    if (['dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicPDFDesigned'].includes(data.type)) {
        url = process.env.TEMPLATES_URL;
    }

    // If templates are designed in the Template Designer, you can give select the option if an image should be the background image
    // If we find an image that has this setting, we collect the model of that image (because TD create random keys for each layer)
    // If that model is found, the user can crop the background image for each format seperately
    let backgroundModel;
    let bottomSpacing = 0;
    if (['dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicPDFDesigned'].includes(data.type)) {
        backgroundModel = TemplateHelpers.findBackgroundModelInAssetData(assetData);
        if (!!backgroundModel && !isPreview) canEditBackground = true;
    }

    if (data.type === 'dynamicVideoDesigned') {
        bottomSpacing = 26;
    }

    const newType = ['dynamicInDesign', 'dynamicPDFDesigned'].includes(data.type) ? data.type : 'dynamicImage';

    // Prepare data
    return formatsData.map((formatItem, i) => {
        const scale = GenericHelpers.getScale(
            newType,
            formatItem.format,
            formatItem.width,
            formatItem.height,
            maxWidthScale,
            maxHeightScale,
            formatsData.length,
            defaultFullSize
        );
        const displayWidth = formatItem.width * scale;
        const displayHeight = formatItem.height * scale + bottomSpacing;

        const backgroundSizes = DataHelpers.getValue(data, 'assetSetup.settings.backgroundSizes');
        const backgroundSize =
            backgroundSizes && backgroundSizes[formatItem.format] ? backgroundSizes[formatItem.format] : { width: formatItem.width, height: formatItem.height };
        const currentCategoryKey = subsetActive + '_' + formatItem.format;
        const isActive =
            (viewType === 'comments' &&
                (!commentsCategoryKey || commentsCategoryKey === 'all' || commentsCategoryKey === 'general' || commentsCategoryKey === currentCategoryKey)) ||
            (viewType !== 'comments' && (!selectedFormats || selectedFormats.length === 0 || selectedFormats.includes(formatItem.format)));
        const assetUpdateModelFull = dataModel + '.data.' + subsetActive + '.assetData.overwrites.' + formatItem.format;
        const previewDataModel = dataModel + '.data.' + subsetActive + '.previewData.' + language + '.' + formatItem.format;

        let newData = DataHelpers.clone(assetData, false);
        newData = {
            ...newData,
            editmode: !isPreview,
            frameNr,
            format: formatItem.format,
            language,
            positionByLanguage,
            uploadByLanguage,
            market: editor.market,
            template,
            scale
        };

        // Merge overwrites
        let hasOverwrites = false;
        if (assetData && assetData.overwrites && assetData.overwrites[formatItem.format] && !isEmpty(assetData.overwrites[formatItem.format])) {
            hasOverwrites = true;
            newData = merge(newData, assetData.overwrites[formatItem.format]);
        }

        return {
            key: 'image' + i + '_' + (formatItem.formatKey ? formatItem.formatKey : formatItem.format) + '_' + subsetActive + '_' + language,
            className: 'creative-builder-item__subitem ' + (!isActive ? 'creative-builder-item__subitem--inactive' : ''),
            width: displayWidth,
            height: displayHeight,
            component: (
                <PreviewFrame
                    type={newType}
                    // Display
                    format={formatItem.format}
                    displayWidth={displayWidth}
                    displayHeight={displayHeight}
                    width={formatItem.width}
                    height={formatItem.height}
                    bottomSpacing={bottomSpacing}
                    scale={scale}
                    title={formatItem.title}
                    loadInView={formatsData.length > 5 ? loadInView : false}
                    onChangeScale={onChangeScale}
                    // Comments
                    viewType={viewType}
                    commentCount={commentsCountsByCategory[currentCategoryKey]}
                    commentsCategoryKey={commentsCategoryKey}
                    onCommentsOpen={onCommentsOpen}
                    // Data
                    url={url}
                    data={newData}
                    // Overwrite options
                    canOverwrite={!isPreview}
                    hasOverwrites={hasOverwrites}
                    assetUpdateModel={assetUpdateModel}
                    assetUpdateModelFull={assetUpdateModelFull}
                    previewDataModel={previewDataModel}
                    // Selectable items
                    isSelected={selectedFormats.includes(formatItem.format)}
                    onSelectItem={() => onSelectFormat(formatItem.format)}
                    canSelect={!isPreview && isSelectable}
                    isPreview={isPreview}
                    showFormatTitle={showFormatTitle}
                    onEditBackground={onEditBackground}
                    backgroundSize={backgroundSize}
                    canEditBackground={canEditBackground}
                    // Background model
                    backgroundModel={backgroundModel}
                    template={template}
                />
            )
        };
    });
}
