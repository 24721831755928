import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withResources } from 'components/data/Resources';
import { withComponentStore } from 'components/data/ComponentStore';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import ChannelItem from './channel-item';
import AssetItem from './asset-item';
import '../styles/main.scss';

/**
 * CreativeBuilderItem
 * Renders an item from the creative builder
 * This directly outputs an asset (displayAd, dynamicImage, dynamicPDF, dynamicVideo, dynamicAfterEffects or dynamicInDesign) or a channelItem.
 */
class CreativeBuilderItem extends React.Component {
    static propTypes = {
        // The full data to be pushed
        data: PropTypes.object,
        // To datamodel to use
        dataModel: PropTypes.string,
        // The current language
        language: PropTypes.string,
        // The active subset
        subsetActive: PropTypes.string,
        // If true, uses the previewformats
        isPreview: PropTypes.bool,
        // Check whether we are showing the editor
        isEditor: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const { data, inTemplateDesigner, loadTemplates = true } = this.props;

        /**
         * If the Creative Builder is opened via Template Designer (test mode), then the template data already exists in the Templates memory.
         * This is done in the topbar component of Template Designer.
         * Otherwise you would see the save data from the last time the template has saved.
         */
        if (!inTemplateDesigner || !loadTemplates) {
            await CreativeBuilderHelpers.loadTemplates(data);
        }

        this.setState({ loading: false });
    }

    /**
     * Render the component
     */
    render() {
        const { data, className, loading } = this.props;

        // Select the correct subcomponent based on the asset type and pass all props
        return (
            <div className={classNames('creative-builder-item', className)} data-tour-selector="builder-item">
                {!loading && (
                    <>
                        {data.type === 'socialChannelItem' && <ChannelItem {...this.props} />}
                        {data.type !== 'socialChannelItem' && <AssetItem {...this.props} />}
                    </>
                )}
            </div>
        );
    }
}

export default withComponentStore(withResources(CreativeBuilderItem, [{ resource: 'templateFormats', path: 'resources/load' }]), 'CreativeEditor', [
    'language',
    'editor'
]);
